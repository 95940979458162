//================================================== CONFIG BUILD SERVER: 120.138.74.69:8080 [ZH-LiveLike]
module.exports = {
	title: 'ZH',
	server: 'Beta',

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether show the settings right-panel
	//----------------------------------------
	showSettings: true,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether need tagsView
	//----------------------------------------  
	tagsView: true,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether fix the header
	//----------------------------------------

	fixedHeader: false,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether show the logo in sidebar
	//----------------------------------------

	sidebarLogo: false,

	//----------------------------------------
	// @type {string | array} 'production' | ['production', 'development']
	// @description Need show err logs component.
	// The default is only used in the production env
	// If you want to also use it in dev, you can pass ['production', 'development']
	//----------------------------------------

	errorLog: 'production',

	//----------------------------------------
	// @type {string}
	// @description Url of token validate, pls dont change this config
	//----------------------------------------

	token_url: 'https://us-central1-zombie-survival-95140593.cloudfunctions.net',

	//----------------------------------------
	//  @type {string}
	//  @description Url of base services
	//----------------------------------------

	firebase_url: 'https://us-central1-zombie-hunter-live-like.cloudfunctions.net',

	//----------------------------------------
	// @type {string}
	// @description Url of link to login
	//----------------------------------------

	service_login: 'https://madops.api-mps.mto.zing.vn/login',
	service_logout: 'https://madops.api-mps.mto.zing.vn/logout',

	//----------------------------------------
	//  @type {string}
	//  @description Services:
	//----------------------------------------

	// service UserProfile
	urlServiceUserProfile: 'http://120.138.74.69:8082/user',

	// service Clan
	urlServiceClan: 'http://120.138.74.69:8082/clan',

	// service Message
	urlServiceMessage: 'http://120.138.74.69:8082/message',

	// service Admin
	urlServiceAdmin: 'http://120.138.74.69:8082/admin',

	// service ClanLDB
	urlServiceClanLDB: 'http://120.138.74.69:8082/clanldb',


	//----------------------------------------
	// @type {string}
	// @description Url of auth link after to login
	//----------------------------------------

	//auth_redirect: 'https://madops.api-mps.mto.zing.vn/ds/#/auth-redirect',
	auth_redirect: 'http://120.138.74.69:8082/upload/zs/admintool/#/auth-redirect',
	//auth_redirect: 'http://120.138.74.69:8080/tool_zsadmin_qc/#/auth-redirect',
	//auth_redirect: 'http://localhost:8080/#/auth-redirect',

	//logout_redirect: 'https://madops.api-mps.mto.zing.vn/ds/#/auth-redirect',  
	//logout_redirect: 'http://120.138.74.69:8080/tool_zsadmin_qc/#/auth-redirect',
	//logout_redirect: 'http://localhost:8080/#/login',
	//logout_redirect: 'http://120.138.74.69:8080/upload/zs/admintool/#/auth-redirect',
	logout_redirect: 'http://120.138.74.69:8082/upload/zs/admintool/#/login',
	logout_redirect_ios: 'http://120.138.74.69:8082/upload/zs/admintool/#/login_ios',

	// custom leaderboard url
	leader_board: 'http://120.138.74.69:8082/zslb',
	leader_board_ios: 'http://120.138.74.69:8083/zslb',
	upload_file_CDN: 'http://120.138.74.69:8082/up',
	upload_file_CDN_ios: 'http://120.138.74.69:8083/up',
	leader_board_helper_blacklist: 'http://120.138.74.69:8082/zslb',
	leader_board_helper_blacklist_ios: 'http://120.138.74.69:8083/zslb',
	leader_board_helper: 'http://120.138.74.69:8082/lbhelper',
	leader_board_helper_ios: 'http://120.138.74.69:8083/lbhelper',
	download_file_CDN: 'http://120.138.74.69:8082/uploadTestingCDN/zs/cdn/android',
	download_file_CDN_ios: 'http://120.138.74.69:8082/uploadTestingCDN/zs/cdn/ios',
}