const serverLocal = require("./settings_server_local.js");
const serverDev = require("./settings_server_dev.js");
const serverBeta = require("./settings_server_beta.js");
const serverLive = require("./settings_server_live.js");
const serverDeviOS = require("./settings_server_dev_ios.js");
const serverBetaiOS = require("./settings_server_beta_ios.js");
const serverLiveiOS = require("./settings_server_live_ios.js");

//module.exports = serverLocal
//module.exports = serverDev
//module.exports = serverBeta
module.exports = serverLive
//module.exports = serverDeviOS
//module.exports = serverBetaiOS
//module.exports = serverLiveiOS

