import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'

export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/loginvng/index'),
        hidden: true
    },
    {
        path: '/login_android',
        component: () => import('@/views/loginvng/index'),
        hidden: true
    },
    {
        path: '/login_ios',
        component: () => import('@/views/loginvng/index'),
        hidden: true
    },
    {
        path: '/logout',
        component: () => import('@/views/loginvng/logout'),
        hidden: true
    },
    {
        path: '/auth-redirect',
        component: () => import('@/views/loginvng/auth-redirect'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                meta: { title: 'Dashboard', icon: 'dashboard', affix: true }
            }
        ]
    },
    {
        path: '/users',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/users/index'),
                name: 'Users',
                meta: { title: 'Users', icon: 'user', noCache: true }
            }
        ]
    },
    {
        path: '/testing_users',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/testing_users/index'),
                name: 'Testing Users',
                meta: { title: 'Testing Users', icon: 'user', noCache: true }
            }
        ]
    },
    {
        path: '/user/:user_id',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/user-info/index'),
                name: 'User Infomation',
                meta: { title: 'User infomation', icon: 'user', noCache: true },
                hidden: true
            }
        ]
    },
    {
        path: '/giftcodes',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/giftcodes/index'),
                name: 'Gift Codes',
                meta: { title: 'Gift Codes', icon: 'money', noCache: true }
            }
        ]
    },
    {
        path: '/items',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/items/index'),
                name: 'Game Items',
                meta: { title: 'Game Items', icon: 'shopping', noCache: true }
            }
        ]
    },
    {
        path: '/ldb-score',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/ldb-score/index'),
                name: 'LDB score',
                meta: { title: 'LDB score', icon: 'leaderboard', noCache: true }
            }
        ]
    }, 
    {
        path: '/sendmail/:user_id',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/send-mail/index'),
                name: 'Send Mail',
                meta: { title: 'Send Mail', icon: 'email', noCache: true }
            }
        ]
    },
    {
        path: '/user-conditions',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/user-conditions/index'),
                name: 'User Filters',
                meta: { title: 'User Filters', icon: 'people', noCache: true }
            }
        ]
    },
    {
        path: '/user-profile',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/views/user-profile/index'),
                name: 'User Profile',
                meta: { title: 'User Profile', icon: 'user', noCache: true }
            }
        ]
    },
    {
        path: '/ldb_services',
        component: Layout,
        meta: { title: 'LDB Services', icon: 'component', noCache: true },
        children: [
            {
                path: '/game_service_lbq',
                component: () => import('@/views/ldb_services/ldb_service_lbq/index'),
                name: 'Event LeaderBoard',
                meta: { title: 'Event LeaderBoard', icon: 'clipboard', noCache: true }
            },
            {
                path: '/game_service_raidboss',
                component: () => import('@/views/ldb_services/ldb_service_raidboss/index'),
                name: 'Event RaidBoss',
                meta: { title: 'Event RaidBoss', icon: 'clipboard', noCache: true }
            },
            {
                path: '/game_service_decoration',
                component: () => import('@/views/ldb_services/ldb_service_decoration/index'),
                name: 'Event Decoration',
                meta: { title: 'Event Decoration', icon: 'clipboard', noCache: true }
            }
        ]
    },
    {
        path: '/dynamic_services',
        component: Layout,
        meta: { title: 'Dynamic Services', icon: 'component', noCache: true },
        children: [
            {
                path: '/dynamic_offers',
                component: () => import('@/views/dynamic_services/dynamic_offers/index'),
                name: 'Dynamic Offers',
                meta: { title: 'Dynamic Offers', icon: 'clipboard', noCache: true }
            },
            {
                path: '/dynamic_config',
                component: () => import('@/views/dynamic_services/dynamic_config/index'),
                name: 'Dynamic Configs',
                meta: { title: 'Dynamic Configs', icon: 'clipboard', noCache: true }
            },
            {
                path: '/dynamic_assets',
                component: () => import('@/views/dynamic_services/dynamic_assets/index'),
                name: 'Dynamic Assets',
                meta: { title: 'Dynamic Assets', icon: 'clipboard', noCache: true }
            },
            {
                path: '/dynamic_events',
                component: () => import('@/views/dynamic_services/dynamic_events/index'),
                name: 'Dynamic Events',
                meta: { title: 'Dynamic Events', icon: 'clipboard', noCache: true }
            }
        ]
    },
    {
        path: '/clan',
        component: Layout,
        meta: { title: 'Clan', icon: 'component', noCache: true },
        children: [
            {
                path: '/clan-info',
                component: () => import('@/views/clan/clan-info/index'),
                name: 'Clan Info',
                meta: { title: 'Clan Info', icon: 'clipboard', noCache: true }
            },
            {
                path: '/clan-ldb',
                component: () => import('@/views/clan/clan-ldb/index'),
                name: 'Clan LDB',
                meta: { title: 'Clan LDB', icon: 'clipboard', noCache: true }
            },
            {
                path: '/clan-mail',
                component: () => import('@/views/clan/clan-mail/index'),
                name: 'Clan Mail',
                meta: { title: 'Clan Mail', icon: 'clipboard', noCache: true }
            },
            {
                path: '/clan-ldb-log',
                component: () => import('@/views/clan/clan-ldb-log/index'),
                name: 'Clan LDB Log',
                meta: { title: 'Clan LDB Log', icon: 'clipboard', noCache: true }
            }
        ]
    },
    {
        path: '/mto-user-profile',
        component: Layout,
        meta: { title: 'Mto User Profile', icon: 'component', noCache: true },
        children: [
            {
                path: '/users-status',
                component: () => import('@/views/mto-user-profile/users-status/index'),
                name: 'Users Status',
                meta: { title: 'MTO Users Status', icon: 'clipboard', noCache: true }
            },
        ]
    },
    {
        path: '/tools',
        component: Layout,
        meta: { title: 'Tools', icon: 'component', noCache: true },
        children: [
            {
                path: '/upload',
                component: () => import('@/views/tools/upload/index'),
                name: 'Upload Files',
                meta: { title: 'Upload Files', icon: 'clipboard', noCache: true }
            },
            {
                path: '/mlb_to_json',
                component: () => import('@/views/tools/mlb_to_json/index'),
                name: 'MLB to Json',
                meta: { title: 'MLB to Json', icon: 'clipboard', noCache: true }
            },
        ]
    },


    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router