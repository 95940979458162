//================================================== CONFIG BUILD SERVER: LOCAL HOST

module.exports = {
	title: 'ZH',
	server: 'Local',

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether show the settings right-panel
	//----------------------------------------
	showSettings: true,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether need tagsView
	//----------------------------------------  
	tagsView: true,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether fix the header
	//----------------------------------------

	fixedHeader: false,

	//----------------------------------------
	// @type {boolean} true | false
	// @description Whether show the logo in sidebar
	//----------------------------------------

	sidebarLogo: false,

	//----------------------------------------
	// @type {string | array} 'production' | ['production', 'development']
	// @description Need show err logs component.
	// The default is only used in the production env
	// If you want to also use it in dev, you can pass ['production', 'development']
	//----------------------------------------

	errorLog: 'production',

	//----------------------------------------
	// @type {string}
	// @description Url of token validate, pls dont change this config
	//----------------------------------------

	token_url: 'https://us-central1-zombie-survival-95140593.cloudfunctions.net',

	//----------------------------------------
	//  @type {string}
	//  @description Url of base services
	//----------------------------------------

	firebase_url: 'http://localhost:5000/ds-test-4135a/us-central1',


	//----------------------------------------
	// @type {string}
	// @description Url of link to login
	//----------------------------------------

	service_login: 'https://madops.api-mps.mto.zing.vn/login',
	service_logout: 'https://madops.api-mps.mto.zing.vn/logout',

	//----------------------------------------
	//  @type {string}
	//  @description Services:
	//----------------------------------------

	// service UserProfile
	urlServiceUserProfile: 'http://localhost:4000/user',

	// service Clan
	urlServiceClan: 'http://localhost:4001/clan',

	// service Message
	urlServiceMessage: 'http://localhost:4004/message',

	// service Admin
	urlServiceAdmin: 'http://localhost:4007/admin',

	// service ClanLDB
	urlServiceClanLDB: 'http://localhost:4008/clanldb',

	//----------------------------------------
	// @type {string}
	// @description Url of auth link after to login
	//----------------------------------------

	//auth_redirect: 'https://madops.api-mps.mto.zing.vn/ds/#/auth-redirect',
	//auth_redirect: 'http://120.138.74.69:8080/upload/zs/admintool/#/auth-redirect',
	auth_redirect: 'http://localhost:8080/#/auth-redirect',

	//logout_redirect: 'https://madops.api-mps.mto.zing.vn/ds/#/auth-redirect',
	//logout_redirect: 'http://120.138.74.69:8080/upload/zs/admintool/#/auth-redirect',
	logout_redirect: 'http://localhost:8080/#/login',
	logout_redirect_ios: 'http://localhost:8080/#/login_ios',

    // custom leaderboard url
    leader_board: 'http://localhost:3001/zslb',
    leader_board_ios: 'http://localhost:3021/zslb',
    upload_file_CDN_local: 'http://localhost:3002/up',
    upload_file_CDN: 'http://120.138.74.69:8080/up',
    upload_file_CDN_ios: 'http://120.138.74.69:8081/up',
    leader_board_helper_blacklist: 'http://localhost:3001/zslb',
    leader_board_helper_blacklist_ios: 'http://localhost:3021/zslb',
    leader_board_helper: 'http://localhost:3003/lbhelper',
    leader_board_helper_ios: 'http://localhost:3023/lbhelper',
    download_file_CDN: 'http://120.138.74.69:8080/uploadTestingCDN/zs/cdn',
    download_file_CDN_ios: 'http://120.138.74.69:8081/uploadTestingCDN/zs/cdn',
    file_list_url: 'http://localhost:8081/zsuploadfile',
    file_list_url_ios: 'http://localhost:8081/zsuploadfile_ios',
  }